/**
 * Table based on the angular CDK table and heavily inspired from angular material table
 * https://github.com/angular/components
 * https://material.angular.io/cdk/table/overview
 * https://material.angular.io/components/table/overview
 */
import {
  CDK_TABLE_TEMPLATE,
  CdkTable,
  CDK_TABLE,
  _CoalescedStyleScheduler,
  _COALESCED_STYLE_SCHEDULER,
  STICKY_POSITIONING_LISTENER,
} from '@angular/cdk/table'
import {ChangeDetectionStrategy, Component, Directive, HostBinding, ViewEncapsulation} from '@angular/core'
import {
  _DisposeViewRepeaterStrategy,
  _RecycleViewRepeaterStrategy,
  _VIEW_REPEATER_STRATEGY,
} from '@angular/cdk/collections'

/**
 * Enables the recycle view repeater strategy, which reduces rendering latency. Not compatible with
 * tables that animate rows.
 */
@Directive({
  selector: 'cft-table[recycleRows], table[cft-table][recycleRows]',
  providers: [{provide: _VIEW_REPEATER_STRATEGY, useClass: _RecycleViewRepeaterStrategy}],
})
export class TableRecycleRowsDirective {}

/**
 * Wrapper for the CdkTable with Material design styles.
 */
@Component({
  selector: 'cft-table, table[cft-table]',
  exportAs: 'cftTable',
  template: CDK_TABLE_TEMPLATE,
  styleUrls: ['./table.css'],
  providers: [
    {provide: _VIEW_REPEATER_STRATEGY, useClass: _DisposeViewRepeaterStrategy},
    {provide: CdkTable, useExisting: TableComponent},
    {provide: CDK_TABLE, useExisting: TableComponent},
    {provide: _COALESCED_STYLE_SCHEDULER, useClass: _CoalescedStyleScheduler},
    // Prevent nested tables from seeing this table's StickyPositioningListener.
    {provide: STICKY_POSITIONING_LISTENER, useValue: null},
  ],
  encapsulation: ViewEncapsulation.None,
  // See note on CdkTable for explanation on why this uses the default change detection strategy.
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TableComponent<T> extends CdkTable<T> {
  @HostBinding('class') class = 'cft-table'
  @HostBinding('class.cft-table-fixed-layout') fixedLayoutClass = this.fixedLayout

  /** Overrides the sticky CSS class set by the `CdkTable`. */
  protected override stickyCssClass = 'cft-table-sticky'

  /** Overrides the need to add position: sticky on every sticky cell element in `CdkTable`. */
  protected override needsPositionStickyOnElement = false
}
