import {NgModule} from '@angular/core'
import {CdkTableModule} from '@angular/cdk/table'
import {TableComponent, TableRecycleRowsDirective} from './table'
import {CommonModule} from '@angular/common'
import {
  CellDefDirective,
  CellDirective,
  ColumnDefDirective,
  FooterCellDefDirective,
  FooterCellDirective,
  HeaderCellDefDirective,
  HeaderCellDirective,
} from './cell'
import {
  FooterRowComponent,
  FooterRowDefDirective,
  HeaderRowComponent,
  HeaderRowDefDirective,
  NoDataRowDirective,
  RowComponent,
  RowDefDirective,
} from './row'
import {TextColumnComponent} from './text-column'

export const EXPORTED_DECLARATIONS = [
  // Table
  TableComponent,
  TableRecycleRowsDirective,

  // Template defs
  HeaderCellDefDirective,
  HeaderRowDefDirective,
  ColumnDefDirective,
  CellDefDirective,
  RowDefDirective,
  FooterCellDefDirective,
  FooterRowDefDirective,

  // Cell directives
  HeaderCellDirective,
  CellDirective,
  FooterCellDirective,

  // Row directives
  HeaderRowComponent,
  RowComponent,
  FooterRowComponent,
  NoDataRowDirective,
  TextColumnComponent,
]

@NgModule({
  imports: [CommonModule, CdkTableModule],
  declarations: EXPORTED_DECLARATIONS,
  exports: EXPORTED_DECLARATIONS,
})
export class TableModule {}
