import {Directive, HostBinding, Input} from '@angular/core'
import {
  CdkCell,
  CdkCellDef,
  CdkColumnDef,
  CdkFooterCell,
  CdkFooterCellDef,
  CdkHeaderCell,
  CdkHeaderCellDef,
} from '@angular/cdk/table'

/**
 * Cell definition for the cft-table.
 * Captures the template of a column's data row cell as well as cell-specific properties.
 */
@Directive({
  selector: '[cftCellDef]',
  providers: [{provide: CdkCellDef, useExisting: CellDefDirective}],
})
export class CellDefDirective extends CdkCellDef {}

/**
 * Header cell definition for the cft-table.
 * Captures the template of a column's header cell and as well as cell-specific properties.
 */
@Directive({
  selector: '[cftHeaderCellDef]',
  providers: [{provide: CdkHeaderCellDef, useExisting: HeaderCellDefDirective}],
})
export class HeaderCellDefDirective extends CdkHeaderCellDef {}

/**
 * Footer cell definition for the cft-table.
 * Captures the template of a column's footer cell and as well as cell-specific properties.
 */
@Directive({
  selector: '[cftFooterCellDef]',
  providers: [{provide: CdkFooterCellDef, useExisting: FooterCellDefDirective}],
})
export class FooterCellDefDirective extends CdkFooterCellDef {}

/**
 * Column definition for the cft-table.
 * Defines a set of cells available for a table column.
 */
@Directive({
  selector: '[cftColumnDef]',
  providers: [{provide: CdkColumnDef, useExisting: ColumnDefDirective}],
})
export class ColumnDefDirective extends CdkColumnDef {
  // when enabled, a sticky column will have a background color to help when content goes underneath it
  @Input()
  get stickyBackground(): boolean {
    return this._stickyBackground
  }
  set stickyBackground(value: boolean) {
    this._stickyBackground = value
    this._updateColumnCssClassName()
  }
  private _stickyBackground = true

  /** Unique name for this column. */
  @Input('cftColumnDef')
  override get name(): string {
    return this._name
  }
  override set name(name: string) {
    this._setNameInput(name)
  }

  protected override _updateColumnCssClassName() {
    super._updateColumnCssClassName()
    // add `cft-column-` prefix in addition to `cdk-column-` prefix.
    this._columnCssClassName!.push(`cft-column-${this.cssClassFriendlyName}`)

    if (this._stickyBackground) {
      this._columnCssClassName!.push(`cft-table-sticky-background`)
    }
  }
}

/** Header cell template container that adds the right classes and role. */
@Directive({
  selector: 'cft-header-cell, th[cft-header-cell]',
})
export class HeaderCellDirective extends CdkHeaderCell {
  @HostBinding('class') class = 'cft-header-cell'
  @HostBinding('role') role = 'columnheader'
}

/** Footer cell template container that adds the right classes and role. */
@Directive({
  selector: 'cft-footer-cell, td[cft-footer-cell]',
})
export class FooterCellDirective extends CdkFooterCell {
  @HostBinding('class') class = 'cft-footer-cell'
  @HostBinding('role') role = 'gridcell'
}

/** Cell template container that adds the right classes and role. */
@Directive({
  selector: 'cft-cell, td[cft-cell]',
})
export class CellDirective extends CdkCell {
  @HostBinding('class') class = 'cft-cell'
  @HostBinding('role') role = 'gridcell'
}
