import {
  CDK_ROW_TEMPLATE,
  CdkFooterRow,
  CdkFooterRowDef,
  CdkHeaderRow,
  CdkHeaderRowDef,
  CdkNoDataRow,
  CdkRow,
  CdkRowDef,
} from '@angular/cdk/table'
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  Directive,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core'

/**
 * Header row definition for the cft-table.
 * Captures the header row's template and other header properties such as the columns to display.
 */
@Directive({
  selector: '[cftHeaderRowDef]',
  providers: [{provide: CdkHeaderRowDef, useExisting: HeaderRowDefDirective}],
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: [
    {name: 'columns', alias: 'cftHeaderRowDef'},
    {name: 'sticky', alias: 'cftHeaderRowDefSticky', transform: booleanAttribute},
  ],
})
export class HeaderRowDefDirective extends CdkHeaderRowDef {}

/**
 * Footer row definition for the cft-table.
 * Captures the footer row's template and other footer properties such as the columns to display.
 */
@Directive({
  selector: '[cftFooterRowDef]',
  providers: [{provide: CdkFooterRowDef, useExisting: FooterRowDefDirective}],
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: [
    {name: 'columns', alias: 'cftFooterRowDef'},
    {name: 'sticky', alias: 'cftFooterRowDefSticky', transform: booleanAttribute},
  ],
})
export class FooterRowDefDirective extends CdkFooterRowDef {}

/**
 * Data row definition for the cft-table.
 * Captures the data row's template and other properties such as the columns to display and
 * a when predicate that describes when this row should be used.
 */
@Directive({
  selector: '[cftRowDef]',
  providers: [{provide: CdkRowDef, useExisting: RowDefDirective}],
})
export class RowDefDirective<T> extends CdkRowDef<T> {
  @Input('cftRowDefColumns') declare columns: Iterable<string>
  @Input('cftRowDefWhen') declare when: (index: number, rowData: T) => boolean
}

/** Header template container that contains the cell outlet. Adds the right class and role. */
@Component({
  selector: 'cft-header-row, tr[cft-header-row]',
  template: CDK_ROW_TEMPLATE,
  // See note on CdkTable for explanation on why this uses the default change detection strategy.
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  exportAs: 'cftHeaderRow',
  providers: [{provide: CdkHeaderRow, useExisting: HeaderRowComponent}],
})
export class HeaderRowComponent extends CdkHeaderRow {
  @HostBinding('class') class = 'cft-header-row'
  @HostBinding('role') role = 'row'
}

/** Footer template container that contains the cell outlet. Adds the right class and role. */
@Component({
  selector: 'cft-footer-row, tr[cft-footer-row]',
  template: CDK_ROW_TEMPLATE,
  // See note on CdkTable for explanation on why this uses the default change detection strategy.
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  exportAs: 'cftFooterRow',
  providers: [{provide: CdkFooterRow, useExisting: FooterRowComponent}],
})
export class FooterRowComponent extends CdkFooterRow {
  @HostBinding('class') class = 'cft-footer-row'
  @HostBinding('role') role = 'row'
}

/** Data row template container that contains the cell outlet. Adds the right class and role. */
@Component({
  selector: 'cft-row, tr[cft-row]',
  template: CDK_ROW_TEMPLATE,
  // See note on CdkTable for explanation on why this uses the default change detection strategy.
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  exportAs: 'cftRow',
  providers: [{provide: CdkRow, useExisting: RowComponent}],
})
export class RowComponent extends CdkRow {
  @HostBinding('class') class = 'cft-row'
  @HostBinding('role') role = 'row'
}

/** Row that can be used to display a message when no data is shown in the table. */
@Directive({
  selector: 'ng-template[cftNoDataRow]',
  providers: [{provide: CdkNoDataRow, useExisting: NoDataRowDirective}],
})
export class NoDataRowDirective extends CdkNoDataRow {
  override _contentClassName = 'cft-no-data-row'
}
